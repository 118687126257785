import React, { useContext, Component } from 'react';
import SlidePreview from './SlidePreview/SlidePreview';
import classes from './SlidePreviews.module.css';
import AuthContext from '../../../context/auth-context';


const SlidePreviews = (props) => {

    const authContext = useContext(AuthContext);

    let test = authContext.testContent;
    let slides = authContext.totalSlidesNum;

    // console.log(slides);

    return (
        <div>
            <div className={classes.Grid}>
                {Array.from(Array(slides).keys()).map((x) => {
                    // console.log('In previews: ', test[x].body);
                    return (
                        < SlidePreview
                            contentArray={test[x]}
                            slideTemplate={test[x].template}
                        />
                    );
                }
                )}
            </div>
        </div>
    );

};

export default SlidePreviews;