import React, { Component } from 'react';
import "./utilities.css";
import Layout from './components/Layout/Layout';
import ContentEditor from './containers/ContentEditor/ContentEditor.js';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckCircle, faStar, faSmile } from '@fortawesome/free-solid-svg-icons'

library.add(faCheckCircle, faStar, faSmile);


class App extends Component {



  render() {
    return (
      <div>
        <Layout>
            <ContentEditor />
        </Layout>

      </div>
    );
  }
}

export default App;
