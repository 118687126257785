import React from 'react';
import classes from './SlidePreview.module.css';
import SlideElement from './SlideElement/SlideElement';
import SlideLayout from './SlideLayout/SlideLayout';


const SlidePreview = (props) => {

    let slideTemplate = null;

    switch (props.slideTemplate) {
        case (""):
            slideTemplate = null;
            break;
        case ("Panel Icon"):
            slideTemplate =
                <div>
                    <div className={classes.SizeSpacer}>
                        <div className={classes.SizeSpacerInside}>
                            <div className={classes.SlideOutline}>
                                <SlideElement type="header">{props.contentArray.header}</SlideElement>
                                <SlideLayout layout="panelSetup">

                                    {Array.from(Array(props.contentArray.subheading.length).keys())
                                        .map((x) => {
                                            // console.log("in preview: "+ props.contentArray.body[x]);
                                            return <SlideLayout layout="iconPanelColumn">
                                                <SlideElement type="spacerTop" />
                                                <SlideElement type="icon" />
                                                <SlideElement type="subheading">{props.contentArray.subheading[x]}</SlideElement>
                                                {/* <SlideElement type="body">{props.contentArray.body[x]}</SlideElement> */}
                                                {Array.from(Array(props.contentArray.body[x].length).keys()).map((y) => {
                                                    return <SlideElement type="body">{props.contentArray.body[x][y]}</SlideElement>
                                                })}
                                                    

                                            </SlideLayout>;

                                        })}
                                </SlideLayout>
                            </div>
                        </div>
                    </div>
                </div>;
            break;
        case ("No Icon Panel"):
            slideTemplate =
                <div>
                    <div className={classes.SizeSpacer}>
                        <div className={classes.SizeSpacerInside}>
                            <div className={classes.SlideOutline}>
                                <SlideElement type="header">{props.contentArray.header}</SlideElement>
                                <SlideLayout layout="panelSetup">

                                    {Array.from(Array(props.contentArray.subheading.length).keys())
                                        .map((x) => {
                                            // console.log("in preview: "+ props.contentArray.body[x]);
                                            return <SlideLayout layout="iconPanelColumn">
                                                <SlideElement type="spacerTop" />
                                                <SlideElement type="subheading">{props.contentArray.subheading[x]}</SlideElement>
                                                {/* <SlideElement type="body">{props.contentArray.body[x]}</SlideElement> */}
                                                {Array.from(Array(props.contentArray.body[x].length).keys()).map((y) => {
                                                    return <SlideElement type="body">{props.contentArray.body[x][y]}</SlideElement>
                                                })}
                                                    

                                            </SlideLayout>;

                                        })}
                                </SlideLayout>
                            </div>
                        </div>
                    </div>
                </div>;
            break;

        default:
            slideTemplate = null;

    }

    return slideTemplate;

};

export default SlidePreview;