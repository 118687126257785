import React from 'react';
import classes from './SlideElement.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const slideElement = (props) => {
    let element = null;

    const bodyProcessor = (bodyText) => {

        let newText = bodyText !== undefined ? bodyText : "";

        // console.log(newText);


        let element = <div></div>;


        if (newText[0] === "{" && newText.search("}") !== -1) {
            element = <div className={classes.CustomGraphic}>
                {"Custom graphic:\n" +
                    newText.substring(1, newText.search("}"))}
            </div>
        } else {
            element = 
            <>
                <div className={classes.Body}>
                    {newText}
                </div>
            </>
            
        }

        return (
            element
        );

    }


    switch (props.type) {
        case ('header'):
            element = <div className={classes.Header}>{props.children}</div>;
            break;
        case ('subheading'):
            element = <div className={classes.Subheading}>
                <div className={classes.SubheadingContent}>
                    {props.children}
                </div>
            </div>
            break;
        case ('body'):
            {
                element = bodyProcessor(props.children);
            }
            break;
        case ('icon'):
            element = <FontAwesomeIcon className={classes.fa} icon="star" size="xs" />
            break;
        case ('spacerTop'):
            element = <div className={classes.SpacerTop}></div>;
            break;
        default:
            element = null;
    }

    return element;

};

export default slideElement;