import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Editor, EditorState, RichUtils, getDefaultKeyBinding, convertToRaw, KeyBindingUtil, Modifier } from 'draft-js';
import 'draft-js/dist/Draft.css';
import './TextEditor.css';

class RichEditorExample extends Component {

    constructor(props) {
        super(props);
        this.state = { editorState: EditorState.createEmpty() };


        this.focus = () => this.refs.editor.focus();


        this.handleKeyCommand = this._handleKeyCommand.bind(this);
        this.mapKeyToEditorCommand = this._mapKeyToEditorCommand.bind(this);
        this.toggleBlockType = this._toggleBlockType.bind(this);
        this.toggleInlineStyle = this._toggleInlineStyle.bind(this);
    }



    onChange = (editorState) => {
     
        let newEditorState = editorState;
        if (!newEditorState.getSelection().getHasFocus()) { newEditorState = EditorState.moveFocusToEnd(newEditorState) }

        this.setState(
            { editorState: newEditorState },
            () => { this.onChange2(newEditorState) },
        );
    };

    onChange2 = (editorState) => {
        this.props.data(convertToRaw(editorState.getCurrentContent()).blocks);
    }

    _handleKeyCommand(command, editorState) {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (command === 'bbbold') {
            newState = RichUtils.toggleInlineStyle(this.state.editorState, 'BOLD');
        }

        if (newState) {
            this.onChange(newState);
            return true;
        }
        return false;
    }

    _mapKeyToEditorCommand(e) {
        if (e.keyCode === 9 /* TAB */) {
            let editorState = this.state.editorState;
            const selection = editorState.getSelection();
            const contentState = editorState.getCurrentContent();
            const blockStartKey = selection.getStartKey();

            if (contentState.getBlockMap().get(blockStartKey).getType() === "unstyled" &&
                convertToRaw(editorState.getCurrentContent()).blocks.length !== 1) {

                let newEditorState = RichUtils.toggleBlockType(
                    editorState,
                    "unordered-list-item"
                );

                this.onChange(newEditorState);

                // newEditorState = EditorState.moveFocusToEnd(newEditorState);

                // this.setState(
                //     { editorState:newEditorState },
                //     () => { this.onChange2(newEditorState) },
                // );

                // return;

            }
            else {
                const newEditorState = RichUtils.onTab(
                    e,
                    editorState,
                    4, /* maxDepth */
                );
                if (newEditorState !== editorState) {
                    this.onChange(newEditorState);
                }
                return;
            }
        }
        return getDefaultKeyBinding(e);
    }

    _toggleBlockType(blockType) {
        this.onChange(
            RichUtils.toggleBlockType(
                this.state.editorState,
                blockType
            )
        );
    }

    _toggleInlineStyle(inlineStyle) {
        this.onChange(
            RichUtils.toggleInlineStyle(
                this.state.editorState,
                inlineStyle
            ) 
        );
    }

    getSelectedBlock = (editorState) => {
        const selection = editorState.getSelection();
        const contentState = editorState.getCurrentContent();
        const blockStartKey = selection.getStartKey();

        return contentState.getBlockMap().get(blockStartKey);
    }


    isEmptyListItem = (block) => {
        const text = block.getText();
        const hasEmptyText = text.length === 0;
        const blockType = block.getType();
        const isListItemBlock = blockType === 'unordered-list-item' || blockType === 'ordered-list-item';

        return isListItemBlock && hasEmptyText;
    }

    isEmptyUnstyledItem = (block) => {
        const text = block.getText();
        const hasEmptyText = text.length === 0;
        const blockType = block.getType();
        const isListItemBlock = blockType === 'unstyled';

        return isListItemBlock && hasEmptyText;
    }


    getSelectedBlock = (editorState) => {
        const selection = editorState.getSelection();
        const contentState = editorState.getCurrentContent();
        const blockStartKey = selection.getStartKey();

        return contentState.getBlockMap().get(blockStartKey);
    }

    handleReturn = (e) => {
        const { editorState } = this.state;
        const block = this.getSelectedBlock(editorState);

        if (this.isEmptyListItem(block)) {
            this.handleReturnForListItem(block);
            return 'handled';
        }

        return 'not-handled';
    }

    handleShortcut = (e) => {
        const { editorState } = this.state;
        const block = this.getSelectedBlock(editorState);

        if (this.isEmptyUnstyledItem(block)) {
            this.handleReturnForStartList(block);
            return 'handled';
        }

        return 'not-handled';
    }

    handleReturnForListItem = (block) => {
        const { editorState } = this.state;
        const depth = block.getDepth();
        if (depth > 0) {
            this.onChange(this.decreaseBlockDepth(block, editorState));
        } else if (depth === 0) {
            this.onChange(this.changeBlockType(editorState));
        }
    }

    handleReturnForStartList = (block) => {
        const { editorState } = this.state;
        const depth = block.getDepth();

        this.onChange(this.changeBlockTypeToList(editorState)
        );

    }


    changeBlockType = (editorState) => EditorState.push(
        editorState,
        RichUtils.tryToRemoveBlockStyle(editorState),
        'change-block-type',
    );

    changeBlockTypeToList = (editorState) => EditorState.push(
        editorState,
        RichUtils.toggleBlockType(editorState),
        'change-block-type',
    );



    decreaseBlockDepth = (block, editorState) => {
        const blockKey = block.getKey();
        const depth = block.getDepth();
        const newBlock = block.set('depth', depth - 1);
        const contentState = editorState.getCurrentContent();
        const blockMap = contentState.getBlockMap();
        const newBlockMap = blockMap.set(blockKey, newBlock);
        return EditorState.push(
            editorState,
            contentState.merge({ blockMap: newBlockMap }),
            'adjust-depth'
        );
    }


    render() {
        const { editorState } = this.state;

        // If the user changes block type before entering any text, we can
        // either style the placeholder or hide it. Let's just hide it now.
        let className = 'RichEditor-editor';
        var contentState = editorState.getCurrentContent();
        if (!contentState.hasText()) {
            if (contentState.getBlockMap().first().getType() !== 'unstyled') {
                className += ' RichEditor-hidePlaceholder';
            }
        }

        return (
            <div className="RichEditor-root">
                <BlockStyleControls
                    editorState={editorState}
                    onToggle={this.toggleBlockType}
                />
                {/* <InlineStyleControls
                    editorState={editorState}
                    onToggle={this.toggleInlineStyle}
                /> */}
                <div className={className} onClick={this.focus}>
                    <Editor
                        blockStyleFn={getBlockStyle}
                        customStyleMap={styleMap}
                        editorState={editorState}
                        handleKeyCommand={this.handleKeyCommand}
                        keyBindingFn={this.mapKeyToEditorCommand}
                        onChange={this.onChange}
                        placeholder={"\nType content here:\n \u2022 Use bullets (\u2022) to indicate content structure\n \u2022 Use {} for {Custom element placeholder}"}
                        ref="editor"
                        spellCheck={true}
                        handleReturn={this.handleReturn}
                    />
                </div>
            </div>
        );
    }
}

// Custom overrides for "code" style.
const styleMap = {
    CODE: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
        fontSize: 16,
        padding: 2,
    },
};

function getBlockStyle(block) {
    // console.log(block.getData());
    switch (block.getType()) {
        case 'unstyled': return 'RichEditor-newSlide';
        default: return null;
    }
}

class StyleButton extends React.Component {
    constructor() {
        super();
        this.onToggle = (e) => {
            e.preventDefault();
            this.props.onToggle(this.props.style);
        };
    }

    render() {
        let className = 'RichEditor-styleButton';
        if (this.props.active) {
            className += ' RichEditor-activeButton';
        }

        return (
            <span className={className} onMouseDown={this.onToggle}>
                {this.props.label}
            </span>
        );
    }
}

const BLOCK_TYPES = [
    // { label: 'H1', style: 'header-one' },
    // { label: 'H2', style: 'header-two' },
    // { label: 'H3', style: 'header-three' },
    // { label: 'H4', style: 'header-four' },
    // { label: 'H5', style: 'header-five' },
    // { label: 'H6', style: 'header-six' },
    // { label: 'Blockquote', style: 'blockquote' },
    { label: '\u2022 Bullet', style: 'unordered-list-item' },
    // { label: '1. List', style: 'ordered-list-item' },
    // { label: 'Code Block', style: 'code-block' },
];

const BlockStyleControls = (props) => {
    const { editorState } = props;
    const selection = editorState.getSelection();
    const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();

    return (
        <div className="RichEditor-controlsContainer">

            <div className="RichEditor-controls">
                {BLOCK_TYPES.map((type) =>
                    <StyleButton
                        key={type.label}
                        active={type.style === blockType}
                        label={type.label}
                        onToggle={props.onToggle}
                        style={type.style}
                    />
                )}
            </div>
            <div className="RichEditor-vl"></div>

            <div className="RichEditor-slideElements">{"Slide\n elements:"}</div>
        </div>
    );
};

var INLINE_STYLES = [
    // { label: 'Bold', style: 'BOLD' },
    // { label: 'Italic', style: 'ITALIC' },
    // { label: 'Underline', style: 'UNDERLINE' },
    // { label: 'Monospace', style: 'CODE' },
];

// const InlineStyleControls = (props) => {
//     const currentStyle = props.editorState.getCurrentInlineStyle();

//     return (
//         <div className="RichEditor-controls">
//             {INLINE_STYLES.map((type) =>
//                 <StyleButton
//                     key={type.label}
//                     active={currentStyle.has(type.style)}
//                     label={type.label}
//                     onToggle={props.onToggle}
//                     style={type.style}
//                 />
//             )}
//         </div>
//     );
// };

//   ReactDOM.render(
//     <RichEditorExample />,
//     document.getElementById('target')
//   );

export default RichEditorExample