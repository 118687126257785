import React, { useContext } from 'react';
import classes from './KeyDisplay.module.css';
import Aux from '../../../../hoc/Auxillary';
import KeyItem from './KeyItem/KeyItem';
import AuthContext from '../../../../context/auth-context';


const KeyDisplay = (props) => {

    const authContext = useContext(AuthContext);


    const assignedClasses = [classes.PreviewBlock];
    switch (props.numLines) {
        case 1:
            assignedClasses.push(classes.lines1);
            break;
        case 2:
            assignedClasses.push(classes.lines2);
            break;
        case 3:
            assignedClasses.push(classes.lines3);
            break;
        case 4:
            assignedClasses.push(classes.lines4);
            break;
        case 5:
            assignedClasses.push(classes.lines5);
            break;
        case 6:
            assignedClasses.push(classes.lines6);
            break;
        case 7:
            assignedClasses.push(classes.lines7);
            break;
        case 8:
            assignedClasses.push(classes.lines8);
            break;
        case 9:
            assignedClasses.push(classes.lines9);
            break;
        case 10:
            assignedClasses.push(classes.lines10);
            break;
        default:
            assignedClasses.push(classes.lines1);
            break;
    }

    return (
        <Aux>
            <div className={classes.LineBreak} />
            <div className={classes.Container}>
                <div className={assignedClasses.join(' ')}>
                {Array.from(Array(props.depthList.length).keys())
                    .map((i) => {

                        // console.log('In KeyDisplay: ', props.depthList);
                        return (
                            <KeyItem
                                depth={props.depthList[i]}
                            />
                
                        );
                    }
                    )}

                </div>
            </div>

        </Aux>
    );


}

export default KeyDisplay;