import React, { Component } from "react";
import classes from './SlidePanel.module.css';
import SlidePreviews from './SlidePreviews/SlidePreviews';
import pptxgen from "pptxgenjs";


class SlidePanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            content: this.props.content,
            slideNum: this.props.slideNum,
        };
    }

    doNothing() {

    }

    exportPPT() {
        let pres = new pptxgen();

        pres.defineSlideMaster({
            title: "PanelIcon",
            background: { color: "FFFFFF" },
            objects: [
                { rect: { x: 0, y: 0, w: "100%", h: 1, fill: { color: "84ccfc" } } },
            ],
        });
        

        for(let i=0; i<this.state.slideNum; i++){
            let slide = pres.addSlide({masterName: "PanelIcon"});
            slide.addText(this.state.content[i].header, {
                x:0.5,
                y:0,
                w:6,
                h:1,
                color: '363636',
                fill: { color: '84ccfc'},
                align: pres.AlignH.left,
            });
            for(let j=0; j<this.state.content[i].subheading.length; j++){
                let margin = .1/(this.state.content[i].subheading.length+1);
                let width = .9/this.state.content[i].subheading.length;
                let marginPercentage = (Math.round(parseFloat(margin)*10000)/100);
                let widthPercentage = (Math.round(parseFloat(width)*10000)/100);
                
                slide.addShape(pres.ShapeType.star5, { 
                    fill: { color: "b4c4dc" },
                    x:((j*widthPercentage) + (j+1)*marginPercentage).toString()+"%",
                    y:1.15,
                    w:0.4,
                    h:0.4, 
                });

                slide.addText(this.state.content[i].subheading[j], {
                    x:((j*widthPercentage) + (j+1)*marginPercentage).toString()+"%",
                    y:1.75,
                    w:(widthPercentage.toString())+"%",
                    h:.8,
                    color: '1305cc',
                    fill: { color: 'e4f3fc'},
                    align: pres.AlignH.left,
                    valign: pres.AlignH.top,
                });


                for(let k=0; k<this.state.content[i].body[j].length; k++){
                    let theMargin;
                    let theHeight;
                    if(this.state.content[i].body[j].length===1){
                        theMargin = .1;
                        theHeight = 1;
                    }else{
                        theMargin = .1/(this.state.content[i].body[j].length-1);
                        theHeight = .9/(this.state.content[i].body[j].length);
                    }

                    slide.addText(this.state.content[i].body[j][k], {
                        x:((j*widthPercentage) + (j+1)*marginPercentage).toString()+"%",
                        y: 2.75 + 2.5*k*(theMargin + theHeight),
                        w:(widthPercentage.toString())+"%",
                        h: 2.5*theHeight,
                        color: '777777',
                        fill: { color: 'ffffff'},
                        align: pres.AlignH.left,
                        valign: pres.AlignV.top,
                    });
                }

                
            }
        }


        // let pptx = new PptxGenJS();
        // pptx.layout = "LAYOUT_WIDE";

        // pptx.defineSlideMaster({
        //     title: "PLACEHOLDER_SLIDE",
        //     background: { color: "FFFFFF" },
        //     objects: [
        //         { rect: { x: 0, y: 0, w: "100%", h: 0.75, fill: { color: "F1F1F1" } } },
        //         { text: { text: "Status Report", options: { x: 0, y: 0, w: 6, h: 0.75 } } },
        //         {
        //             placeholder: {
        //                 options: { name: "body", type: "body", x: 0.6, y: 1.5, w: 12, h: 5.25 },
        //                 text: "(custom placeholder text!)",
        //             },
        //         },
        //     ],
        //     slideNumber: { x: 0.3, y: "95%" },
        // });

        // let slide = pptx.addSlide({ masterName: "PLACEHOLDER_SLIDE" });

        // // Add text, charts, etc. to any placeholder using its `name`
        // slide.addText("Body Placeholder here!", { placeholder: "body" });

        pres.writeFile("Sample Presentation.pptx");
    }

    componentDidMount() {
        document.getElementById("exportButton").addEventListener("click", () => {
            this.setState({
                content: this.props.content,
                slideNum: this.props.slideNum,
            }, () => {
                // console.log(this.state.content);
                this.exportPPT();
            })
        });
    }

    render() {
        return (
            <div>
                <h3>Slide Panel</h3>
                <button id="exportButton">Export</button>
                <hr className={classes.Line} />
                <SlidePreviews 
                    // contentArray={this.props.textRaw} 
                />
            </div>

        );
    };
}

export default SlidePanel;
