import React, { Component } from "react";
import classes from './TextPanel.module.css';
import TextEditor from './TextEditor/TextEditor';
import TemplatePanel from './TemplatePanel/TemplatePanel';
import KeyPanel from './KeyPanel/KeyPanel';


class TextPanel extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value: ",,",
            block: undefined,
        };
    }


    // componentDidMount = () => {
    //     document.getElementById("slideTemplate").addEventListener("change", () => {
    //         this.props.selectedTemplate(document.getElementById("slideTemplate").value);
    //     })
    // }


    render() {
        return (
            <div>
                <h3>Content flow</h3>
      
                <hr className={classes.Line} />
                <div className={classes.SideBySide}>
                    <KeyPanel />
                    <TextEditor
                        data={this.props.data}
                    ></TextEditor>
                    <TemplatePanel 
                        manualTemplateChanger={this.props.manualTemplateChanger}
                    />
                </div>
            </div>

        );
    }
}

export default TextPanel;