import React, { Component } from 'react';
import Aux from '../../hoc/Auxillary';
import SlidePanel from '../../components/SlidePanel/SlidePanel';
import TextPanel from '../../components/TextPanel/TextPanel';
import "./ContentEditor.css"
import "../../utilities.css";
import AuthContext from '../../context/auth-context';

const DEFAULT_TEMPLATE = "Panel Icon";

class ContentEditor extends Component {


    state = {
        totalSlides: 0,
        block: {}, //for rich text input DraftJS functionality
        textClassified: [{}],
    }

    //Every time rich text input changes, this code runs
    //block is the object version of rich text input

    update(value) {
        this.setState({
            block: value,
        }, () => { this.onChangeHandler() }
        );
    }

    manualTemplateChanger = (newTemplate, position) => {
        /*
        This function is passed onto text panel components. Defined here to update state.
        input: string of template name. position to update
        output: updated state with new templates. Overwrites current state
        */

        let updatedTextClassified = [...this.state.textClassified];

        updatedTextClassified[position].template = newTemplate;

        this.setState({
            textClassified: updatedTextClassified,
        })

    }


    onChangeHandler = (event) => {
        //TODO: change so that not everything runs every keystroke
        this.textHandler();
    };


    // TEXT HANDLERS
    textHandler = () => {
        /*
        This executes all of the functions in order. The state is updated with multiTemplateFiller and numberOfSlidesFinder.
        The order of functions is:
        - Determine number of slides as count of 'unstyled' lines. 
        - Take the raw text and determine where the new lines are by searching for '/n'
        - Change the raw text to an array. Each line of text goes to an array element
        - Create another array of same length with the depth of each line as each element
        - Split text array into array of arrays. Each element is a slide, which contains array of text with each element line
        - Split text array into array of arrays. Each element is a slide, which contains array of depths with each element depth
        - Loop over each of first level of arrays (each slide). Then within each slide choose the template, then populate the Object state 
        - Each template chosen is always a panelIcon, which is now more dynamic. Later on, the depth array can be a lookup library
        - Each object element populated using array of object values. E.g. subheading: [] contains all subheadings
        - Parent child relationships of content are ignored 
        */

        const richTextInput = this.state.block; //DraftJS format

        let numSlides = this.numberOfSlidesFinder(richTextInput);

        //Split text into array of lines and depths
        let dividedText = this.textSlideDivider(richTextInput, numSlides);
        let dividedDepth = this.depthSlideDivider(richTextInput, numSlides);
        let numLinesArray = this.numLinesFinder(dividedText, numSlides);

        //Update state and chose template for each slide
        this.multiTemplateFiller(numSlides, dividedDepth, dividedText, numLinesArray);
    }

    numberOfSlidesFinder = (richTextInput) => {
        /*
        Uses 'unstyled' (i.e. no bullet points) to determine new slides. 
        input: DraftJS block
        output: integer number of slides 
        */

        //Count number of type of unstyled
        let unstyledNum = 0;

        //length is each line. Loop over each line.
        for (let i = 0; i < richTextInput.length; i++) {
            if (richTextInput[i].type === "unstyled") {
                unstyledNum++;
            }
        }

        //Check to see whether a new slide has been added
        // if (unstyledNum > this.state.totalSlides) {
        //     console.log("NEW SLIDE ADDED");
        // }

        this.setState({
            totalSlides: unstyledNum,
        });
        return unstyledNum;
    }


    textSlideDivider = (richTextInput, numSlides) => {
        /*
        Loop over each line to split text into slide arrays: [[line1A,line1B],[line2A,line2B]]  
        input: DraftJS block, number of slides
        output: array (each slide) of arrays (each line within slide).   
        */
        let headerIndex = -1;
        let dividedText = Array(numSlides).fill([]);

        //loop over every line and find first new slide
        for (let i = 0; i < richTextInput.length; i++) {
            if (richTextInput[i].type === "unstyled") {
                headerIndex = i;
                break;
            }
        }

        let slideIndex = -1;

        if (headerIndex > -1) {
            // If there's a header, loop over each line of text, starting at first header. 
            for (let i = headerIndex; i < richTextInput.length; i++) {
                // If new slide, add to new element, otherwise push to existing element. 
                if (richTextInput[i].type === "unstyled") {
                    slideIndex++;
                    dividedText[slideIndex] = [richTextInput[i].text];
                } else {
                    dividedText[slideIndex].push(richTextInput[i].text);
                }
            }
        }

        // console.log("dividedText: " + JSON.stringify(dividedText));
        return dividedText;
    }


    depthSlideDivider = (richTextInput, numSlides) => {
        /*
        Loop over each line to split text into depth arrays: [[1,2],[1,2,3,2,3]]  
        input: DraftJS block, number of slides
        output: array (each slide) of arrays (each depth within slide).   
        */

        let headerIndex = -1;
        let dividedDepth = Array(numSlides).fill([]);

        for (let i = 0; i < richTextInput.length; i++) {
            if (richTextInput[i].type === "unstyled") {
                headerIndex = i;
                break;
            }
        }

        let slideIndex = -1;

        if (headerIndex > -1) {
            // If there's a header, loop over each line of text, starting at first header. 
            for (let i = headerIndex; i < richTextInput.length; i++) {
                // If new slide, add to new element, otherwise push to existing element.
                if (richTextInput[i].type === "unstyled") {
                    slideIndex++;
                    dividedDepth[slideIndex] = [richTextInput[i].depth + 1];
                } else {
                    //Assume if not 'unstyled', then is a bullet point
                    if (richTextInput[i].depth === 0) {
                        dividedDepth[slideIndex].push(2);
                    } else {
                        //Catch-all assume depth of 3, which would usually go to 'body'
                        dividedDepth[slideIndex].push(3);
                    }
                }
            }
        }

        // console.log("dividedDepth: " + JSON.stringify(dividedDepth));
        return dividedDepth;
    }


    numLinesFinder = (dividedText, numSlides) => {
        /*
        Loop over each slide in dividedText to get number of lines  
        input: dividedText, number of slides
        output: array (each slide) number of lines within slide. Will be used for template selection.    
        */
        let numLinesArray = [];

        for (let i = 0; i < numSlides; i++) {
            numLinesArray.push(dividedText[i].length);
        }

        // console.log("numLinesFinder: ", numLinesArray);
        return numLinesArray;
    }



    multiTemplateFiller = (numSlides, dividedDepth, dividedText, numLinesArray) => {
        /*
        Called every rich text change. 
        handler function that sources template and puts into correct form for state
        input: num slides, then arrays of text, depth, lines for each slide
        output: none. state updated of form [{template: XXX, header: "", subheaders: [""]}, {next slide}]
        */
        let selectedTemplate = DEFAULT_TEMPLATE;
        let templateOptions = [DEFAULT_TEMPLATE];
        let multiTemplates = [];

        for (let i = 0; i < numSlides; i++) {
            //for each slide, find the right template, add to object, then push to state. 

            templateOptions = this.templateOptionsArray(i, numSlides, dividedDepth);
            selectedTemplate = this.templateSelector(i, numSlides, templateOptions);
            multiTemplates.push(this.templateFiller(selectedTemplate, templateOptions, dividedText[i], dividedDepth[i], numLinesArray[i]));
        }

        this.setState({
            textClassified: multiTemplates,
        });

        console.log("multiTemplates: ", multiTemplates);

    }

    templateOptionsArray = (slideIndex, numSlides, dividedDepth) => {
        /*
        Called every rich text change from within multiTemplateFiller. 
        inputs: numslide. Then whatever is needed to choose template.
        outputs: string of the template option, to be included in drop down. 
        */
        let templateOptionsArray = ["Panel Icon", "No Icon Panel"];

        if (this.state.textClassified[slideIndex] !== undefined) {
            //check whether the state is defined yet
            if (this.state.textClassified[slideIndex].templateOptions === undefined){
                //New slide created      
                // console.log("selectedTemplate: ", this.state.textClassified[numSlides - 1]);
                if (slideIndex === numSlides - 1) {
                    templateOptionsArray = ["Panel Icon", "No Icon Panel"];
                }else{
                    //not at end of array. keep as default
                }

            }

            else {

                // not a new slide. use existing
                templateOptionsArray = this.state.textClassified[slideIndex].templateOptions;
            }
        } else {
            //state undefined. leave as default

        }
        return templateOptionsArray;
    }

    templateSelector = (slideIndex, numSlides, templateOptionsArray) => {
        /*
        Called every rich text change from within multiTemplateFiller. 
        inputs: list of possible templates
        outputs: string of the selected template. 
        */

        let selectedTemplate = DEFAULT_TEMPLATE;

        if (this.state.textClassified[slideIndex] !== undefined) {
            //check whether the state is defined yet
            if (this.state.textClassified[slideIndex].template === undefined){
                //New slide created at end      
                // console.log("selectedTemplate: ", this.state.textClassified[numSlides - 1]);
                if (slideIndex === numSlides - 1) {
                    selectedTemplate = templateOptionsArray[0];
                }

            }

            else {

                // not a new slide. use existing
                selectedTemplate = this.state.textClassified[slideIndex].template;
            }
        } else {
            //state undefined. leave as default

        }


        return selectedTemplate;

    }

    templateFiller = (selectedTemplate, templateOptions, lineSplitText, depthList, numLines) => {
        /*
        Called every rich text change from within multiTemplateFiller. 
        inputs: single slide of: selected template, dividedText, depth, lines 
        outputs: object to be used to populate single slide.
        {template: "", header: "", subheaders: [""], body:[[""]], numLines: X, depth: []}
        */

        // let updatedTextProcessed = { ...this.state.textClassified };

        // start with given info
        let updatedTextProcessed = {
            numLines: numLines,
            template: selectedTemplate,
            templateOptions: templateOptions,
            depthList: depthList
        };

        // the header is always the first text
        updatedTextProcessed = {
            ...updatedTextProcessed,
            ...{ header: lineSplitText[0] }
        };

        // the subheader and body depend on the template. 
        // Current algorithm not scalable. 
        switch (selectedTemplate) {
            case "Panel Icon":
            case "No Icon Panel":
                //add subheading and body placeholder
                updatedTextProcessed = {
                    ...updatedTextProcessed,
                    ...{
                        subheading: [],
                        body: [],
                    }
                };


                for (let i = 0; i < depthList.length; i++) {
                    //for each line, check depth and process
                    if (depthList[i] === 2) {
                        //depth 2 is subheading
                        updatedTextProcessed.subheading.push(lineSplitText[i]);
                    } else if (depthList[i] === 3) {
                        // is body. need to handle multi-body and parent-child relationships. 
                        if (updatedTextProcessed.subheading.length - 1 > updatedTextProcessed.body.length) {
                            // e.g. 2 subheadings. no body groups yet. Means that one subheading is blank.
                            while (updatedTextProcessed.subheading.length - 1 !== updatedTextProcessed.body.length) {
                                //Add blank body text until 1:1 relationship. Effectively makes previous subheading have blank body
                                updatedTextProcessed.body.push([""]);
                            }
                        }

                        if (updatedTextProcessed.subheading.length === updatedTextProcessed.body.length) {
                            //Means previous entries have 1:1 subheading:body.  Append to previous end
                            console.log("BODY.LENGTH:", updatedTextProcessed.body.length);
                            if (updatedTextProcessed.body.length > 0 ){
                                updatedTextProcessed.body[updatedTextProcessed.body.length - 1].push(lineSplitText[i]);

                            }else{
                                updatedTextProcessed.body[updatedTextProcessed.body.length] = [lineSplitText[i]];
                            }
                        } else {
                            // Add to end   
                            updatedTextProcessed.body[updatedTextProcessed.body.length] = [lineSplitText[i]];
                        }

                    }
                }

                //After running through each line, add blanks at end to keep body:subheading relationship 1:1
                while (updatedTextProcessed.body.length < updatedTextProcessed.subheading.length) {
                    updatedTextProcessed.body.push([""]);
                }

                break;

            default:
                break;
        }
        // console.log("updatedTextProcessed: ", { ...updatedTextProcessed });

        return { ...updatedTextProcessed };

    }

    render() {
        // const eventHandler = data => console.log(data);

        return (
            //pass content through context
            <AuthContext.Provider value={{ testContent: this.state.textClassified, totalSlidesNum: this.state.totalSlides }}>

                <Aux>

                    <div className="Content-textPanelContainer u-textCenter">
                        {/* <TextPanel onChange={eventHandler}></TextPanel> */}
                        <TextPanel
                            data={this.update.bind(this)}
                            manualTemplateChanger={this.manualTemplateChanger.bind(this)}
                        />
                    </div>
                    <hr className="Content-line" />
                    <div className="Content-slidePanelContainer u-textCenter">
                        <SlidePanel
                            content={this.state.textClassified}
                            slideNum={this.state.totalSlides}
                        />
                    </div>
                </Aux>
            </AuthContext.Provider>

        );
    }

}

export default ContentEditor;

