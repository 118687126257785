import React, { Component } from "react";
//import logo from '../../logo.svg';
import logo from "../../theLogo.png";
import "./NavBar.css";
import "../../utilities.css";


/**
 * The navigation bar at the top of all pages. Takes no props.
 */
class NavBar extends Component {
  render() {
    return (
      <nav className="NavBar-container">
        <img src={logo} className="NavBar-logo" alt="logo" />
        <div className="NavBar-title u-bold">Flow</div>
      </nav>
    );
  }
}
          


export default NavBar;
