import React, { useContext } from 'react';
import classes from './KeyPanel.module.css';
import AuthContext from '../../../context/auth-context';
import KeyDisplay from './KeyDisplay/KeyDisplay';

const KeyPanel = (props) => {

    const authContext = useContext(AuthContext);


    return (
        <div className={classes.KeySetup}>

            <div className={classes.TopSpacer}></div>
            <div className={classes.KeyPanel} >

                {Array.from(Array(authContext.totalSlidesNum).keys())
                    .map((i) => {

                        // console.log('In KeyPanel: ', authContext.testContent[i].depthList);
                        return (
                            <KeyDisplay
                                numLines={authContext.testContent[i].numLines}
                                depthList = {authContext.testContent[i].depthList}
                            />
                
                        );

                    }
                    )}
            </div>


        </div>
    );


}

export default KeyPanel;