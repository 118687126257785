import React from 'react';
import classes from './SlideLayout.module.css';


const SlideLayout = (props) => {
    let layout = null;

    switch (props.layout) {
        case ('panelSetup'):

            layout = <div className={classes.PanelSetup}>{props.children}</div>;
            break;

        case ('iconPanelColumn'):
            layout = <div className={classes.IconPanelColumn}>
                {props.children}
            </div>;
            break;


        default:
            layout = null;
    }

    return layout;
}

export default SlideLayout;