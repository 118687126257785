import React, { useContext } from 'react';
import classes from './KeyItem.module.css';

const KeyItem = (props) => {

    let label = "";

    switch (props.depth) {
        case 1:
            label = "Lead-in:"
            break;
        case 2:
            label = "SubHead:"
            break;
        case 3:
            label = "Body:"
            break;
        case 4:
            label = "Body:"
            break;
        case 5:
            label = "Body:"
            break;
        default:
            label = "";
            break;

    }

    return (
        <div className={classes.KeyItemContainer}>
            <div className={classes.KeyItem}>
                {label}
            </div>

        </div>
    );
}

export default KeyItem;