import React, { useEffect } from 'react';
import classes from './TemplatePreview.module.css';
import Aux from '../../../../hoc/Auxillary';

const TemplatePreview = (props) => {

    const assignedClasses = [classes.SelectBlock];
    switch (props.numLines) {
        case 1:
            assignedClasses.push(classes.lines1);
            break;
        case 2:
            assignedClasses.push(classes.lines2);
            break;
        case 3:
            assignedClasses.push(classes.lines3);
            break;
        case 4:
            assignedClasses.push(classes.lines4);
            break;
        case 5:
            assignedClasses.push(classes.lines5);
            break;
        case 6:
            assignedClasses.push(classes.lines6);
            break;
        case 7:
            assignedClasses.push(classes.lines7);
            break;
        case 8:
            assignedClasses.push(classes.lines8);
            break;
        case 9:
            assignedClasses.push(classes.lines9);
            break;
        case 10:
            assignedClasses.push(classes.lines10);
            break;
        default:
            assignedClasses.push(classes.lines1);
            break;
    }



    const selectTemplateHandler = (event) => {
        // console.log("in select handler:" + event.target.value)
        // call the state changer that has been passed through
        props.manualTemplateChanger(event.target.value, props.order)
    }



    let options = props.templateOptions.map((templateOption, i) => {

        return (
            <option key={i} value={templateOption}>{templateOption}</option>
        );
    });


    return (
        <Aux>
            <div className={classes.LineBreak} />
            <div className={classes.Container}>


                <select name={"templateSelect" + props.order}
                    id={"templateSelect" + props.order}
                    onChange={selectTemplateHandler}
                    className={assignedClasses.join(' ')}>
                    {options}
                </select>

            </div>

        </Aux>
    );


}

export default TemplatePreview;