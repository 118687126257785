import React, { useContext } from 'react';
import classes from './TemplatePanel.module.css';
import TemplatePreview from './TemplatePreview/TemplatePreview';
import AuthContext from '../../../context/auth-context';

const TemplatePanel = (props) => {

    const authContext = useContext(AuthContext);

    return (
        <div className={classes.TemplateSetup}>

            <div className={classes.TopSpacer}>Template Manager</div>
            <div className={classes.TemplatePanel} >
                
                {Array.from(Array(authContext.totalSlidesNum).keys())
                    .map((i) => {
                        // console.log('In previews: ', authContext.testContent[i].numLines);
                        return (
                            < TemplatePreview
                                numLines={authContext.testContent[i].numLines}
                                numSlides={authContext.totalSlidesNum}
                                order={i}
                                manualTemplateChanger={props.manualTemplateChanger}
                                templateOptions={authContext.testContent[i].templateOptions}
                                // slideTemplate={authContext.testContent[i].template}
                            />
                        );
                    }
                )}
            </div>
            <div className={classes.ButtonContainer}>
                <button className={classes.NewSlideButton}>Start from template (disabled)</button>
            </div>                
        </div>
    );


}

export default TemplatePanel;